.menu {
  margin: 0;
  padding: 0;

  list-style-type: none;

  &_layout_auto,
  &_layout_vertical,
  &_layout_horizontal {
    display: flex;
  }

  &_layout_vertical {
    flex-direction: column;
  }

  &_layout_auto,
  &_layout_horizontal {
    flex-direction: row;
  }

  &__item {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 880px) {
  .menu {
    &_layout_auto {
      flex-direction: column;
    }
  }
}