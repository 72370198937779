.checkbox {
  display: grid;
  grid-template-columns: 36px 1fr;
  gap: 16px;

  user-select: none;

  &__mark {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    width: 36px;
    height: 36px;

    border-radius: 36px;
    border: 2px solid var(--yellow);

    cursor: pointer;
  }

  &__mark .icon {
    display: none;
  }

  input:checked + &__mark {
    background-color: var(--yellow);
    border: none;
  }

  input:checked + &__mark .icon {
    display: block;
  }

  &__label {
    margin-top: 6px;

    cursor: pointer;
  }

  input {
    position: absolute;
    left: 0;

    width: 0;
    right: 0;

    -webkit-appearance: none;
    appearance: none;
  }
}