@import "../App//core.scss";

.template-list {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;

  background: black;
  border-radius: 22px;

  &__item {
    padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);

    color: white;
    border: 1px solid var(--dark-gray);
    border-width: 1px 0 0 0;

    text-decoration: none;

    background-repeat: no-repeat;
  }

  a.template-list__item:hover {
    background-color: #000b33;
  }

  &__item_soon &__title {
    color: var(--dark-gray);
  }

  body &__title {
    margin: 0 0 12px;
  }

  &__soon {
    margin: 0 0 14px;

    color: var(--yellow);
  }

  &__description {
    font: var(--body);

    white-space: pre-wrap;

    margin: 4px 0 0;
  }
}

@include largeScreen {
  .template-list {
    grid-auto-rows: 1fr;

    &__item {
      border-left-width: 1px;

      &:nth-child(odd) {
        border-left: none;
      }

      &:nth-child(-n+2) {
        border-top: none;
      }
    }
  }
}

@include smallScreen {
  .template-list {
    grid-template-columns: 1fr;
    grid-auto-rows: 182px;
  }
}
