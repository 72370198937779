.group-statistic {
  &__item {
    padding: 20px 0;
    text-decoration: none;
    color: white;
    position: relative;
    display: block;
    transition: transform ease-out 200ms;

    &:hover:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 0;
      right: -20px;
      bottom: 0;
      background: #000B33;
      z-index: 1;
    }

    &:hover,
    &:hover + & {
      border-top-color: transparent !important;
    }

    &:active {
      transform: scale(0.98);
    }

    & + & {
      border-top: 1px solid #444;
    }
  }

  &__container {
    display: flex;
    position: relative;
    z-index: 2;
  }

  &__thumb {
    width: 120px;
    min-height: 120px;
    box-sizing: border-box;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  &__content {
    margin-left: 20px;
    flex-grow: 1;
    padding-bottom: 8px;
  }

  &__question {
    font-size: 16px;
    line-height: 20px;
    padding-top: 4px;
  }

  &__stats {
    font-size: 16px;
    line-height: 20px;

    margin-top: 20px;
    padding-right: 40px;

    &-item {
      display: flex;

      &-tag {
        width: 24px;
        text-transform: uppercase;
        color: #ffcc00;
        flex-shrink: 0;
        font-weight: 800;
      }

      &-value {
        background: #ffcc00;
      }

      &-percent {
        margin-left: 8px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }
}