.selection-area {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  cursor: crosshair;
  user-select: none;

  &__selection {
    position: absolute;

    border: 1px solid #000;
    border-radius: 4px;
  }
}