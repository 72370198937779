.case-page {
  min-height: 100vh;

  color: #fff;
  background: #000;

  &__wrap {
    max-width: 880px;
    margin: 0 auto;
    padding: 80px 0;
  }
}