@import "../App/core.scss";

.image-uploader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background: var(--light-gray);
  color: #000;

  cursor: pointer;

  &__zoom {
    position: absolute;
    z-index: 30;
    bottom: 8px;
    left: 8px;

    &-items {
      display: flex;

      background: white;
      border-radius: 12px;
      box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.5);
    }

    &-item {
      text-transform: uppercase;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 42px;
      height: 42px;

      border-radius: 12px;

      &_selected {
        background: var(--yellow);
      }
    }
  }

  &__preview {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;

    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  &__preview-image {
    position: relative;
    z-index: 20;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__system {
    display: none;
  }

  &__label {
    font: var(--large-title);
    text-align: center;

    display: block;

    color: #000;
  }

  &__plus {
    position: absolute;
    z-index: 20;
    right: 4px;
    bottom: 2px;
  }
}

@include largeScreen {
  .image-uploader {
    &_view-original &__preview-image {
      max-width: none;
      max-height: none;
      object-fit: fill;
      object-position: center top;
    }
  }
}