.heatmap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #fff;

  &__image {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    opacity: 0.6;
    filter: grayscale(100%);
  }

  &__canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.72;
  }
}
