.selection-item {
  position: absolute;
  user-select: none;
  border: 2px solid black;
  border-radius: 6px;
  box-sizing: border-box;

  &__controls {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: none;

    user-select: none;
  }

  &:hover &__controls {
    display: flex;
  }

  &_view &__controls {
    top: 0;
  }

  &:hover &__value {
    display: none;
  }

  &__button {
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;

    min-width: 50%;
    padding: 6px 0;

    color: white;
    background: black;

    cursor: pointer;

    &:first-child {
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
    }

    &_type_remove {
      background: red;
    }
  }

  &__value {
    font-size: 14px;
    line-height: 14px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 800;
    position: absolute;
    left: 0;
    top: 0;

    padding: 2px 4px 3px;

    color: #ffcc00;
    background: black;
    border-radius: 0 0 6px 0;
  }

}