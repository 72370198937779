@import "../App/core.scss";

.example {
  position: relative;

  border-radius: 22px;
  background: #000;

  .title {
    margin-bottom: 20px;
  }

  &__content {
    padding: 40px;
  }

  h2 + p {
    margin-top: 28px;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 800;
    color: #ffcc00;
  }

  h3 + p {
    margin-top: 24px;
  }

  h3 + &__tasks {
    margin-top: 24px;
  }

  &__tasks + h3 {
    margin-top: 40px;
  }

  p {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: #eee;

    span {
      font-style: italic;
      font-weight: 600;
      color: #ffcc00;
    }
  }

  p + p {
    margin-top: 24px;
  }

  p + &__image {
    margin-top: 28px;
  }

  p + h3 {
    margin-top: 40px;
  }

  p a {
    color: #fc0;
  }

  &__image {
    text-align: center;
    border: 24px solid #444;
    line-height: 0px;
    font-size: 0;

    img,
    video {
      max-width: 100%;
      transition: opacity ease-out 200ms;
      border-radius: 2px;
      overflow: hidden;
      display: block;
      line-height: 0px;
      font-size: 0;
      overflow: hidden;
      border: 0;
    }
  }

  &__image + p {
    margin-top: 28px;
  }

  &__image + h3 {
    margin-top: 40px;
  }
}

@include largeScreen {
  .example {
    &__content {
      padding: 60px var(--horizontal-edge-offset) 120px;
    }

    h2 {
      font-size: 50px;
      line-height: 60px;

      max-width: 720px;
    }

    p {
      font-size: 24px;
      line-height: 36px;

      max-width: 600px;
    }
  }
}

@include smallScreen {
  .example {
    &__content {
      padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);
    }
  }
}
