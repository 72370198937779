@import "../App/core.scss";

.header {
  box-sizing: border-box;

  padding: 36px 40px 24px;

  &__menu {
    a {
      color: var(--main);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include largeScreen {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sandwich-menu {
      display: none;
    }

    &__menu {
      gap: 15px;
    }
  }
}

@include smallScreen {
  .header {
    padding: 16px 20px 20px;

    .sandwich-menu + .logo {
      display: none;
    }

    &__menu {
      display: none;
    }
  }
}