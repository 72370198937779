.sandwich-menu {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  .title {
    text-align: center;

    color: #000;
  }

  &__arrow {
    margin-right: -24px;
  }

  &__menu {
    font-weight: bold;
    text-transform: uppercase;

    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;

    align-items: center;

    background: #fff;

    a {
      color: #000;
    }

    .menu__item {
      font: var(--medium-title);

      padding: 16px 0;
    }
  }
}