@import "inter-ui/inter.css";
@import "./core.scss";

html,
body {
  font: var(--body);

  margin: 0;
  padding: 0;

  background: white;
  color: var(--main);
}

a {
  text-decoration: none;
}