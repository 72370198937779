@import "../App/core.scss";

.profile-form {
  padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);

  & .spin {
    margin-left: 10px;
  }

  & a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input {
    margin-top: 24px;
  }

  &__form {
    margin: 0;
  }

  &__label {
    display: block;

    margin: 0 0 24px;

    &-text {
      font-size: 16px;
      line-height: 19px;

      display: block;

      margin: 0 0 6px;

      color: #fff;

      & span {
        color: #999;
      }
    }
  }

  .checkbox {
    margin: 32px 0 0;
  }

  &__status {
    visibility: hidden;

    margin: 24px 0;

    &_visible {
      visibility: visible;
    }

    i {
      font-style: normal;

      margin-right: 0.5em;
    }
  }

  &__foot {
    display: grid;
  }

  &__foot,
  & .error {
    margin-top: 36px;
  }

  &__delete {
    color: var(--medium-gray);
  }

  &__confirm {
    color: #f00;
  }
}

@include largeScreen {
  .profile-form {
    .input {
      display: block;

      max-width: 50%;
    }

    &__foot {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    &__foot-col:last-child {
      text-align: right;
    }
  }
}

@include smallScreen {
  .profile-form {
    &__content {
      max-width: 50%;
    }

    &__foot-col:first-child {
      margin: 0 0 31px;
    }

    &__foot-col:last-child {
      text-align: center;
    }
  }
}