@import "../../components/App/core.scss";

.page_blog {
  .auth-form {
    margin-top: var(--vertical-edge-offset);
  }

  .auth-form__step {
    min-height: 400px;
  }
}

@include largeScreen {
  .page_blog {
    .page__body {
      --horizontal-edge-offset: 120px;

      max-width: 1040px;
    }

    .auth-form__step_type_terms {
      padding-left: var(--horizontal-edge-offset);
      padding-right: var(--horizontal-edge-offset);
    }
  }
}