.moderation {
  &__row {
    position: relative;

    margin: 0 0 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &_centered {
      text-align: center;
      max-width: 880px;

      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;

    margin: 0;
    padding-bottom: 24px;

    color: #fc0;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;

    margin: 0;
    padding: 8px 0;
  }

  &__moderator {
    font-size: 16px;
    text-align: center;

    margin: -10px 0 0;

    color: #fff;
  }

  &__foot {
    text-align: center;

    & .button {
      font-size: 20px;
      line-height: 20px;

      margin: 0 40px;

      color: #fff;

      &:disabled {
        opacity: 0.4;
      }
    }

    & [type="submit"] {
      color: #fc0;
    }
  }

  &__answers-list {
    margin: 0;
    padding: 0;

    list-style-type: none;
  }

  &__answers-item {
    font-size: 20px;
    line-height: 24px;

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 32px;
  }

  &__answers-text {
    text-align: left;

    flex: 1;
    border-bottom: 1px solid #444;

    padding: 18px 0 22px;
  }

  &__answers-item:first-child &__answers-text {
    border-top: 1px solid #444;
  }

  &__answers-list_selectable &__answers-item {
    cursor: pointer;
  }

  &__answers-list_selectable &__answers-item:hover {
    color: #fc0;
  }

  &__answers-list_selectable &__answers-item {
    cursor: pointer;
  }

  &__answers-list_selectable &__answers-item:hover {
    color: #fc0;
  }

  &__answers-item_bad {
    color: #fff!important;
    text-decoration: line-through;
  }

  &__answers-item_bad &__answers-text {
    opacity: 0.4;
  }

  &__deleted {
    .clickable-area__click {
      width: 8px;
      height: 8px;
      margin: -4px 0 0 -4px;

      border-radius: 8px;
      background: rgba(255, 0, 0, 0.6);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  &__responses {
    text-align: left;

    &-item {
      padding: 18px 0;

      cursor: pointer;
      border-top: 1px solid #9e9e9e;
    }
  }

  &__group {
    font-size: 16px;
    line-height: 20px;

    b {
      font-weight: 700;
      text-transform: uppercase;
      display: block;

      color: #fc0;
    }

    p {
      margin: 0;
    }
  }

  &__group + &__group {
    margin-top: 10px;
  }

  &__responses-item_bad,
  &__responses-item_bad b {
    text-decoration: line-through;

    color: #6a6a6a;
  }

  &__preview {
    text-align: center;
    text-decoration: none;

    &-container {
      padding: 24px;

      border-radius: 20px;
      background-color: #ebebeb;
    }

    &-image {
      height: 240px;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    &-label {
      font-size: 40px;
      font-weight: 700;
      line-height: 38px;

      margin: 16px 0 0;

      color: #fc0;
    }
  }
}