@import "../App/core.scss";

.input {
  font: inherit;

  box-sizing: border-box;

  margin: 0;
  padding: 0;

  border: none;
  outline: none;
  background: none;

  -webkit-appearance: none;
  appearance: none;

  &::placeholder {
    color: var(--medium-gray);
  }

  &_view_default {
    text-align: center;

    width: 100%;
    padding: 18px;

    border-radius: 12px;
    background: #fff;
  }

  &_view_round {
    text-align: left;

    width: 100%;
    padding: 18px 26px;

    border-radius: 40px;
    background: #fff;
  }
}