.accordion {
  text-align: left;

  border-top: 1px solid var(--dark-gray);
  border-bottom: 1px solid var(--dark-gray);

  &__head {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 0;

    cursor: pointer;
    user-select: none;
  }

  &_opened &__head {
    border-bottom: 1px solid var(--dark-gray);
  }

  &_opened .icon {
    transform: rotate(180deg);
  }

  &__title {
    margin: 0!important;
  }

  &__body {
    display: none;
  }

  &_opened &__body {
    display: block;
  }

  & + & {
    border-top: none;
  }
}