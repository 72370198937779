@mixin largeScreen {
  @media (min-width: 880px) {
    @content;
  }
}

@mixin smallScreen {
  @media (max-width: 880px) {
    @content;
  }
}

$fontFamily: -apple-system, BlinkMacSystemFont, "Inter var", Helvetica, Arial, sans-serif;

:root {
  --font: -apple-system, BlinkMacSystemFont, "Inter var", Helvetica, Arial, sans-serif;
  --large-title: 900 32px/36px var(--font);
  --medium-title: 900 24px/28px var(--font);
  --small-title: 900 20px/24px var(--font);
  --body: 16px/20px var(--font);

  --medium-gray: rgb(142, 142, 147);
  --light-gray: rgb(229, 229, 234);
  --dark-gray: rgb(72, 72, 74);
  --main: black;
  --yellow: rgb(255, 204, 0);
  --green: rgb(157, 255, 80);

  --vertical-edge-offset: 48px;
  --horizontal-edge-offset: 40px;
}

@include smallScreen {
  :root {
    --vertical-edge-offset: 28px;
    --horizontal-edge-offset: 20px;
  }
}

$accentColor: #fc0;

@keyframes shake-animation {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(1em);
  }
  40% {
    transform: translate(-1em);
  }
  60% {
    transform: translate(1em);
  }
  80% {
    transform: translate(-1em);
  }
  100% {
    transform: translate(0);
  }
}

.shake {
  animation: shake-animation 0.5s;
}