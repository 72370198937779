@import "../App/core.scss";

.new-research-form {
  display: grid;
  gap: 24px;

  padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);

  &__area {
    position: relative;

    overflow: hidden;
  }

  &__grid {
    display: grid;
    gap: 15px;
  }
}

@include largeScreen {
  .new-research-form {
    &__area {
      height: 350px;
    }

    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include smallScreen {
  .new-research-form {
    &__area {
      height: 300px;
    }

    &__grid {
      grid-template-rows: 1fr 1fr;
    }

    &__grid &__area {
      height: 130px;
    }
  }
}