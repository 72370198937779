@import "../App/core.scss";

.rresearch {
  &__row {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &_centered {
      text-align: center;
      max-width: 880px;

      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
    text-align: left;

    margin: 0;
    padding-bottom: 24px;

    color: #fc0;
  }

  &__title + &__title {
    position: absolute;
    right: 0;
    top: 0;

    width: 172px;
  }

  &__subtitle {
    margin: 0 0 20px;

    color: #fff;
  }

  &__answers-list {
    text-align: left;

    margin: 0;
    padding: 0;

    list-style-type: none;

    border-bottom: 1px solid var(--dark-gray);
  }

  &__answers-item {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 12px;
    align-items: center;

    padding: 14px 0;

    border-top: 1px solid var(--dark-gray);
  }

  &__author {
    p {
      margin: 0 0 12px;
    }

    a {
      color: var(--medium-gray);

      &:hover {
        color: var(--yellow);
      }
    }
  }

  &__range {
    display: grid;
    gap: 8px;
    align-items: center;
    grid-template-columns: min-content 1fr min-content;

    margin: 0 0 6px;

    color: var(--yellow);

    input[type="range"] {
      width: 100%;
    }

    input[type=range] {
      width: 100%;
      margin: 18px 0;

      -webkit-appearance: none;
    }
    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;

      border: 0;
      border-radius: 0;
      box-shadow: none;
      background: #fff;

      cursor: pointer;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #fff;
    }

    input[type=range]::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      margin-top: -12px;

      border: 0;
      border-radius: 100%;
      box-shadow: none;
      background: #fc0;

      cursor: pointer;

      -webkit-appearance: none;
    }
  }

  &__wait {
    margin: 72px 0;
    text-align: center;

    color: #fff;
    font-size: 20px;
    line-height: 28px;

    h3 {
      color: #fc0;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px;
      display: block;
      font-weight: 800;

      margin: 0 0 12px;
    }

    p {
      margin: 0;
    }
  }

  &__answer {
    line-height: 20px;
    white-space: pre-wrap;

    padding: 14px 0;

    border-bottom: 1px solid #404040;

    &:last-child {
      border: none;
    }
  }

  &__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  &__preview {
    text-align: center;
    text-decoration: none;

    &-container {
      background-color: #ebebeb;
    }

    &-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 12px 0 0;
    }

    &-label {
      color: var(--yellow);
    }

    &-value {
      color: #fff;
    }
  }

  &__titles {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 16px;
  }
}

@include largeScreen {
  .rresearch {
    &__row {
      margin: 0 0 40px;
    }

    &__preview {
      &-container {
        padding: 24px;

        border-radius: 20px;
      }

      &-image {
        height: 240px;
      }
    }
  }
}

@include smallScreen {
  .rresearch {
    &__row {
      margin: 0 0 24px;
    }

    &__preview {
      &-container {
        padding: 12px;

        border-radius: 12px;
      }

      &-image {
        height: 120px;
      }
    }
  }
}