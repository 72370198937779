.submitable-input {
  position: relative;

  &__button {
    position: absolute;
    right: 4px;
    top: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 48px;
    height: 48px;
    margin: -24px 0 0 0;

    border-radius: 48px;
    background: var(--yellow);

    .icon {
      transform: translateX(2px) rotate(-90deg);
    }
  }

  &_disabled &__button {
    opacity: 0.6;
  }
}