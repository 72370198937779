.error {
  color: #fff;

  &_embed {
    color: #f00;
  }

  &__title {
    font-size: 72px;
    line-height: 72px;

    margin: 0 0 12px;

    color: #fc0;
  }
}