.well-done {
  margin: auto;
  padding: 100px 0;
  max-width: 880px;

  text-align: center;

  color: #fff;

  &__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;

    margin: 0 0 34px 0;

    color: #fc0;
  }

  &__message {
    margin: 0;

    line-height: 28px;
  }
}