@import "../App/core.scss";

.page {
  padding: 0;
}

@include largeScreen {
  .page {
    &__head,
    &__body,
    &__foot {
      margin: auto;
      max-width: 880px;
    }
  }
}

@include smallScreen {
  .page {
    &__head,
    &__body,
    &__foot {
      margin: 0 15px;
      max-width: auto;
    }
  }
}

.backface {
  color: #fff;
  background: #000;
  border-radius: 22px;
}
