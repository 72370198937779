@import "../App/core.scss";

.research-list {
  position: relative;

  min-height: 500px;
  padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);

  .title {
    margin-bottom: 36px;
  }

  &__list {
    margin: 0 calc(var(--horizontal-edge-offset) * -1);
  }

  &__item {
    display: grid;
    gap: 12px;
    grid-template-columns: 36px 1fr min-content min-content;
    align-items: center;

    padding: 16px var(--horizontal-edge-offset);

    color: white;
    border-bottom: 1px solid var(--dark-gray);

    &:hover {
      background-color: #000b33;
    }

    &:first-child {
      border-top: 1px solid var(--dark-gray);
    }
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 36px;
    height: 36px;

    border-radius: 36px;
    background-color: var(--yellow);

    &_type_recent {
      .icon {
        margin-right: -3px;
      }
    }

    &_type_failed {
      background-color: red;
    }

    &_type_completed {
      background-color: var(--green);
    }
  }

  &__date,
  &__type {
    text-transform: uppercase;

    white-space: nowrap;
  }

  &__type {
    color: var(--yellow);
  }

  &__date {
    color: var(--medium-gray);
  }

  &__error {
    margin: 20px 40px;
  }
}

@include smallScreen {
  .research-list {
    &__type,
    &__date {
      display: none;
    }
  }
}