.histogram {
  padding-left: 20px;
  padding-right: 85px;

  &__row {
    position: relative;

    height: 32px;
    min-width: 20px;
    margin-bottom: 16px;

    border-radius: 8px;
    background: #ffcc00;

    transition: width ease-out 0.3s;
  }

  &__stats {
    position: absolute;
    left: 100%;
    top: 0;

    display: flex;

    padding: 6px 0;
  }

  &__label {
    text-transform: uppercase;

    position: absolute;

    margin-left: -20px;
    padding: 6px 0;

    color: var(--yellow);
  }

  &__count {
    color: #ffcc00;
    margin: 0 11px 0 8px;
  }
}