@import "../../components/App/core.scss";

.not-found {
  min-height: 500px;
  padding: var(--vertical-edge-offset) var(--horizontal-edge-offset);

  .title {
    margin: 0 0 20px;
  }

  p {
    margin: 0;
  }
}
