@import "../App/core.scss";

.footer {
  display: flex;
  box-sizing: border-box;

  a {
    text-decoration: none;

    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }

  &__misc a {
    color: var(--medium-gray);
  }
}

@include largeScreen {
  .footer {
    justify-content: space-between;

    padding: 36px 40px;

    .menu {
      gap: 15px;
    }
  }
}

@include smallScreen {
  .footer {
    gap: 70px;

    padding: 36px 20px;

    .menu {
      gap: 8px;
    }
  }
}