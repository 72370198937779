@import "../App/core.scss";


.textarea {
  font: normal 16px/1 $fontFamily;

  box-sizing: border-box;

  margin: 0;
  padding: 0;

  resize: none;
  border: none;
  outline: none;
  background: none;

  -webkit-appearance: none;
  appearance: none;
}