.clickable-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;

  &__click {
    position: absolute;

    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;

    background-color: magenta;
    border-radius: 100%;
  }
}