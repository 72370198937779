@import "../App/core.scss";

.tag-list {
  display: flex;
  flex-direction: row;

  margin: 0;
  padding: 0;

  list-style-type: none;

  &__item {
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;

    text-align: center;

    background: var(--dark-gray);
    border-radius: 100%;

    cursor: pointer;
    user-select: none;

    &:hover {
      color: #000;
      background: var(--yellow);
    }

    &_active {
      background: var(--yellow);
    }
  }
}

@include largeScreen {
  .tag-list {
    gap: 12px;

    &__item {
      width: 48px;
      height: 48px;
    }
  }
}

@include smallScreen {
  .tag-list {
    gap: 4px;

    &__item {
      width: 36px;
      height: 36px;
    }
  }
}