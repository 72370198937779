.aspect-ratio-area {
  user-select: none;

  &_fitted {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
  }

  &__content {
    position: relative;

    margin: auto;
  }

  &_orientation_landscape &__content {
    width: 100%;
  }

  &_orientation_portrait &__content {
    height: 100%;
  }
}