.fade {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;

  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;

  &_visible {
    visibility: visible;
    opacity: 1;
  }
}