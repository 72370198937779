.title {
  text-transform: uppercase;

  margin: 0;

  color: var(--yellow);

  &_size_large {
    font: var(--large-title);
  }

  &_size_medium {
    font: var(--medium-title);
  }

  &_size_small {
    font: var(--small-title);
  }
}