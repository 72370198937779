@import "../App//core.scss";

.auth-form {
  max-width: 100%;
  overflow: hidden;
  transform: translateZ(0); // @see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari

  border-radius: 22px;

  &__pager {
    display: grid;
    grid-template-columns: repeat(10, 100%);
  }

  &__step {
    display: grid;
    min-height: 500px;
    box-sizing: border-box;

    &_type_code,
    &_type_email {
      grid-auto-rows: 1fr 1fr;
    }

    &_type_terms {
      padding: 28px 20px 16px;

      color: #fff;
      background: #000;

      a {
        text-decoration: underline;

        color: #fff;
      }

      .title {
        margin: 0 0 20px;
      }

      .checkbox {
        margin-bottom: 16px;
      }
    }
  }

  &__head {
    text-align: center;

    padding: 40px 0 20px;

    color: #fff;
    background: #000;

    .title {
      margin: 0 0 12px;
    }

    p {
      margin: 0;
    }
  }

  &__head &__content {
    margin: auto;
    max-width: 260px;
  }

  &__body {
    text-align: center;

    padding: 20px 20px 16px;

    background: var(--yellow);

    .title {
      margin: 0 0 16px;

      color: #000;
    }

    .error {
      color: #000;
    }
  }

  &__grid {
    display: grid;
    grid-template-rows: min-content 1fr;
  }

  &__foot {
    display: grid;
    align-items: flex-end;
  }

  &__switch {
    color: #000;
  }
}

@include largeScreen {
  .auth-form {
    &__body &__form {
      margin: auto;
      width: 400px;
    }

    &__step_type_terms &__form {
      width: 400px;
    }
  }
}