.researches-admin {
  margin: 0 40px;

  &__item {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 1fr min-content min-content;

    padding: 8px 0;

    border-top: 1px solid #e4e4e4;

    &:first-child {
      border: none;
    }
  }

  &__await {
    white-space: nowrap;
  }
}