.case {
  padding: 80px 0;

  &__row {
    position: relative;

    margin: 0 0 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &_centered {
      text-align: center;
      max-width: 880px;

      margin-left: auto;
      margin-right: auto;
    }
  }

  // TODO: Fix priority
  & &__textarea {
    font-size: 20px;
    line-height: 24px;

    display: block;

    width: 100%;
    height: 115px;
    padding: 17px 27px;

    background: #fff;
  }

  & &__submit {
    font-size: 20px;

    padding: 5px;

    color: #fc0;

    &:disabled {
      color: #e4e4e4;
    }
  }

  &__question {
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    padding: 20px 10px;

    border: 1px solid #fff;

    &-hint {
      font-size: 16px;
      line-height: 20px;

      opacity: 0.5;
    }

    &-text {
      padding: 8px 0;
    }

    &-text ~ &-hint {
      margin-top: 15px;
    }

    &-text ~ &-text {
      margin-top: 18px;
      margin-bottom: 10px;
    }
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;

    a {
      padding: 25px 0;

      color: #999;
      text-decoration: none;

      &:hover {
        color: #fc0;
        text-decoration: underline;
      }
    }
  }

  &__radio-list {
    margin: 35px 0;

    &-item {
      position: relative;

      margin: 0 50px;

      color: #999;

      &:after {
        position: absolute;
        right: -20px;

        display: none;

        content: "";

        width: 15px;
        height: 15px;
        background: url("../TagList/check.svg") no-repeat 50% 50%;
        background-size: 15px 15px;
      }

      cursor: pointer;
      user-select: none;

      &_checked {
        color: #fc0;

        &::after {
          display: inline;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .case {
    &__question {
      padding: 10px;

      border: none;
    }
  }
}