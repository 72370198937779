@import "../App/core.scss";


.button {
  font: var(--body);

  box-sizing: border-box;

  margin: 0;
  padding: 0;

  border: none;
  outline: none;
  background: none;

  -webkit-appearance: none;
  appearance: none;
  user-select: none;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &_view_action {
    font: var(--medium-title);

    width: 100%;
    height: 56px;

    color: var(--main);
    background-color: var(--yellow);

    border-radius: 12px;
  }

  &_view_action:disabled {
    opacity: 0.4;
  }
}