.spin {
  position: absolute;
  z-index: 1;
  display: inline-block;
  visibility: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 50%
}

.spin:after {
  content: '\00A0'
}

.spin.spin_visible {
  position: relative;
  visibility: visible;
  background: linear-gradient(1deg,rgba(0,0,0,0),rgba(0,0,0,0));
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.spin.spin_visible.spin_theme_default {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear, snip-appear 0s ease-out;
}

.spin.spin_visible.spin_theme_contrast {
  -webkit-animation: spin-contrast 1s infinite linear;
  animation: spin-contrast 1s infinite linear, snip-appear 0s ease-out;
}

.spin.spin_size_xs {
  line-height: 16px;
  width: 16px;
  height: 16px;
  background-position: -2px -2px
}

.spin.spin_size_s {
  line-height: 24px;
  width: 24px;
  height: 24px;
  background-position: -2px -19px
}

.spin.spin_size_m {
  line-height: 28px;
  width: 28px;
  height: 28px;
  background-position: -2px -44px
}

.spin.spin_size_l {
  line-height: 32px;
  width: 32px;
  height: 32px;
  background-position: -2px -73px
}

.spin.spin_size_xl {
  line-height: 38px;
  width: 38px;
  height: 38px;
  background-position: -2px -106px
}

@keyframes snip-appear {
  0%, 90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    border-top-color: #fc0;
    border-left-color: #fc0;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    border-top-color: #fc0;
    border-left-color: #fc0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spin-contrast {
  from {
    border-top-color: #000;
    border-left-color: #000;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    border-top-color: #000;
    border-left-color: #000;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spin_global {
  position: absolute!important;
  left: 50%;
  top: 50%;
}

.spin_global.spin.spin_size_xl {
  margin-left: -8px;
  margin-top: -8px;
}

.spin_global.spin.spin_size_s {
  margin-left: -12px;
  margin-top: -12px;
}

.spin_global.spin.spin_size_m {
  margin-left: -14px;
  margin-top: -14px;
}

.spin_global.spin.spin_size_l {
  margin-left: -16px;
  margin-top: -16px;
}

.spin_global.spin.spin_size_xl {
  margin-left: -19px;
  margin-top: -19px;
}
